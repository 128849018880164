/**
 * Validator model
 */
class Validator {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public enabled: boolean,
    public sequence: number,
    public valueType: string,
    public defaultValue: boolean | number | string | any
  ) {}
}

export default Validator
