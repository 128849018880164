import Axios from 'axios'
import Api from 'utils/Api'
import User from 'models/User'
import Role from 'models/Role'
import SettlementApi from 'utils/SettlementApi'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : { type: Key; payload: M[Key] }
}

export type SessionType = {
  counter: number
  rememberOnDevice: boolean
  accessToken: string | null
  newPassword: string | null
  resetPassword: string | null
  user: User | null
  role: Role | null
}

export type SessionActionPayload = {
  ADD_COUNTER: { count: number }
  GET_USERS_ME: {}
  NEW_PASSWORD: null | string
  RESET_PASSWORD: null | string
  SET_ACCESS_TOKEN: null | string
  SET_USER: User | null
  SET_ROLE: Role | null
  SIGNOUT: null | string
}

export type SessionActionType =
  ActionMap<SessionActionPayload>[keyof ActionMap<SessionActionPayload>]
export const sessionReducer = (
  state: SessionType,
  action: SessionActionType
) => {
  switch (action.type) {
    case 'ADD_COUNTER':
      state.counter += action.payload.count
      return state
    case 'GET_USERS_ME':
      Api.request({
        method: 'GET',
        url: '/users/me'
      }).then(({ status, data }) => {
        console.log('Usersme', status, data)
      })
      return state

    case 'NEW_PASSWORD':
      return { ...state, newPassword: action.payload }
    case 'RESET_PASSWORD':
      return { ...state, resetPassword: action.payload }

    case 'SET_ACCESS_TOKEN':
      Api.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload
      SettlementApi.defaults.headers.common['Authorization'] =
        'Bearer ' + action.payload
      return { ...state, accessToken: action.payload }

    case 'SIGNOUT':
      delete Api.defaults.headers.common['Authorization']
      return { ...state, user: null, accessToken: null }

    case 'SET_USER':
      return { ...state, user: action.payload }

    case 'SET_ROLE':
      return { ...state, role: action.payload }

    default:
      return state
  }
}
