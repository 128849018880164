import { Grid } from '@material-ui/core'
import dayjs from 'dayjs'
import Merchant from 'models/Merchant'
import React, { useState } from 'react'
import { Line } from 'react-chartjs-2'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import { Filter } from './FilterForm'

type Props = {
  filter?: Filter | any
}
export type TransactionMonitoring = {
  [key: string]: MonitoringData
}
export type MonitoringData = {
  [key: string]: number
}

const LineChart: React.FC<Props> = ({ filter }) => {
  const [chartData, setChartData] = useState({})
  const [chartDataTwo, setChartDataTwo] = useState({})
  const [totalAmount, setTotalAmount] = React.useState<number>(0)
  const [groupAmountData, setGroupAmountData] = React.useState<object>({})

  const trxStatus: object = {
    captured: 'green',
    declined: 'yellow',
    failed: 'red'
  }

  React.useEffect(() => {
    dataForChartJSSuccessTRX('captured')
  }, [filter])

  const dataForChartJSSuccessTRX = (status: any) => {
    let where: any = filter || {}
    where.nullCategory = false
    if (filter?.period && filter?.period.length == 2) {
      where.createdTime = {
        between: [
          dayjs(filter?.period[0]).startOf('day').toISOString(),
          dayjs(filter?.period[1]).endOf('day').toISOString()
        ]
      }
      where.period = undefined
    }
    if (filter?.merchantId) {
      where.merchantId = filter?.merchantId
    }
    if (filter?.currency) {
      where.currency = filter?.currency
    } else {
      where.currency = 'IDR'
    }
    if (filter?.paymentSources && filter?.paymentSources.length > 0) {
      where.paymentSource = {
        inq: filter?.paymentSources
      }
    }
    if (filter?.paymentMethod && filter?.paymentMethod.length > 0) {
      let newMethod: string[] = []
      filter?.paymentMethod.map((el: string) => {
        if (el != 'No Category') {
          newMethod.push(el)
        } else {
          where.nullCategory = true
        }
      })
      where.category = {
        inq: newMethod
      }
    }
    where.status = status
    let labels: string[] = []
    var fromDate = new Date(filter?.createdTime.between[0])
    var toDate = new Date(filter?.createdTime.between[1])
    for (var d = fromDate; d <= toDate; d.setDate(d.getDate() + 1)) {
      labels.push(dayjs(d).format('DD/MM/YY'))
    }
    where.dateRange = labels

    Api.request<TransactionMonitoring>({
      method: 'GET',
      url: '/reports/transaction-monitoring',
      params: {
        filter: {
          where
        }
      }
    }).then(({ data }) => {
      let newObject: any = []
      let amount: number = 0
      let trxTotal: object = {}
      Object.keys(data).map((keyVal, key) => {
        let newData = data[keyVal]
        let dataAmount: number[] = []
        Object.keys(newData).map((val, key) => {
          dataAmount[key] = newData[val]
        })
        dataAmount.map((i) => {
          amount += Number(i)
          if (status != 'captured') {
            trxTotal = {
              ...trxTotal,
              [keyVal]:
                (Object.assign(trxTotal)[keyVal]
                  ? Number(Object.assign(trxTotal)[keyVal])
                  : 0) + Number(i)
            }
          }
        })

        let borderColor: string = ''
        if (Object.assign(trxStatus)[keyVal]) {
          borderColor = Object.assign(trxStatus)[keyVal]
        } else {
          borderColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
        }
        newObject[key] = {
          label: keyVal,
          data: dataAmount,
          borderColor: borderColor,
          fill: false,
          pointStyle: 'line',
          borderWidth: 3
        }
      })

      if (status == 'captured') {
        setTotalAmount(amount)
      } else {
        setGroupAmountData(trxTotal)
      }
      if (status == 'captured') {
        setChartData({
          labels: labels,
          datasets: newObject
        })
        dataForChartJSSuccessTRX(['captured', 'declined', 'failed'])
      } else {
        setChartDataTwo({
          labels: labels,
          datasets: newObject
        })
      }
    })
  }
  const legend = {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#323130',
      fontSize: 14,
      usePointStyle: true
    }
  }
  const optionsTrxVol = {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (label: any) {
              return FormatHelper.amountFormatWithOutCurrency(
                label,
                filter?.currency
              )
            }
          }
        }
      ]
    }
  }
  const optionsTrxStatus = {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (label: any) {
              return label.toLocaleString()
            }
          }
        }
      ]
    }
  }
  return (
    <div className="chart-container">
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} md={6}>
          <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>
            Transaction Volume
          </h2>
          <Line options={optionsTrxVol} data={chartData} legend={legend} />
          <div style={{ marginTop: '25px', display: 'flex' }}>
            <label style={{ color: '#939DA0' }}>Total Transaction: </label>
            <div>
              {totalAmount && (
                <div style={{ padding: '0 0 10px 10px' }}>
                  {FormatHelper.currency(filter?.currency, totalAmount)}
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>
            Transaction Status
          </h2>
          <Line
            options={optionsTrxStatus}
            data={chartDataTwo}
            legend={legend}
          />
          <Grid container spacing={2} style={{ marginTop: '15px' }}>
            {groupAmountData &&
              Object.keys(groupAmountData).map((item, key) => {
                return (
                  <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                    <label style={{ color: '#939DA0' }}>{item}: </label>
                    <div style={{ padding: '0 0 10px 10px' }}>
                      {Object.values(groupAmountData)[key].toLocaleString()}
                    </div>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default LineChart