import axios from 'axios'
import Env from './Env'

const SettlementApi = axios.create({
  baseURL: Env.settlementUrl,
  timeout: 60000
})

SettlementApi.defaults.baseURL = Env.settlementUrl

export default SettlementApi
