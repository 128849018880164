import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Typography,
  Theme
} from '@material-ui/core'
import { Hidden } from '@material-ui/core'
import Navigation from 'components/Navigation'
import createNavigationConfig from './navigationConfig'
import { AppContext } from 'contexts/AppContext'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    //overflowY: 'auto'
  },
  content: {
    //padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'linear-gradient(180deg, #9BDAEB  20%, #55BADB 100%)',
    padding: '13px 10px',
    position: 'relative',
    minHeight: '192px',
    zIndex:999,
  },
  avatar: {
    width: 78,
    height: 78
  },
  name: {
    marginTop: theme.spacing(1),
    color: 'white',
    fontWeight: 'bold',
  },
  role: {
    color: 'white',
  },
  divider: {
    marginTop: '25px'
  },
  navigation: {
    //marginTop: theme.spacing(2),
    //padding: theme.spacing(2),
    //padding: '0px 10px 10px 10px',
    //paddingLeft: '10px',
    height: '100vh',
    marginTop: '-192px',
    overflowY: 'auto',
    paddingTop: '222px',
    borderRight: '1px solid #ebedef',
  },
  profileLink: {
    marginTop: theme.spacing(1),
    fontWeight: 'normal',
    fontSize: '0.7em',
    backgroundColor: 'white',
    padding: '7px 13px',
    color: '#13ABD9',
    borderRadius: '4px',
  },
}))

type NavBarProps = {
  className?: string
  onMobileClose?: (...args: any[]) => any
  openMobile?: boolean
}

const NavBar: React.SFC<NavBarProps> = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props
  const classes = useStyles()
  const router = useRouteMatch()

  const context = useContext(AppContext)
  const { state, dispatch } = context
  const [navigationConfig, setNavigationConfig] = useState<any[]>()
  
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    }

    setNavigationConfig(
      createNavigationConfig(context, state.session.user || undefined)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.session.user, router.path])

  const navbarContent = (
    <div>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={
            state.session.user && state.session.user.photoUrl
              ? state.session.user.photoUrl
              : undefined
          }
          to={`/profile/me`}
        />
        <Typography className={classes.name} variant="h4">
          {state.session.user && state.session.user.name}
        </Typography>
        <Typography className={classes.role} variant="body2">
          {state.session.user && state.session.user.roles.join('/')}
        </Typography>
        <RouterLink className={classes.profileLink} to="/profile/me">
          Edit Profile
        </RouterLink>
      </div>

      <nav className={classes.navigation}>
        {navigationConfig &&
          navigationConfig.map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
      </nav>
        
    </div>
  )

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  )
}

export default NavBar
