import dayjs from 'dayjs'

const DEFAULT_NUMBER_FORMAT = Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 4,
  minimumFractionDigits: 0
})

const CURRENCIES_NUMBER_FORMAT: { [k: string]: Intl.NumberFormat } = {
  IDR: Intl.NumberFormat('id-ID', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  USD: Intl.NumberFormat('en-US', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  SGD: Intl.NumberFormat('en-SG', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  MYR: Intl.NumberFormat('en-MY', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  EUR: Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  USDT: Intl.NumberFormat('en-US', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  })
}

const CURRENCIES_NUMBER_FORMAT_CURR: { [k: string]: Intl.NumberFormat } = {
  IDR: Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    currencyDisplay: 'code',
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  USD: Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  SGD: Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    currencyDisplay: 'code',
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  MYR: Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',
    currencyDisplay: 'code',
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  EUR: Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  }),
  USDT: Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 4,
    minimumFractionDigits: 0
  })
}

const parseLocaleNumber = (stringNumber: string, currency: string) => {
  var thousandSeparator = (
    CURRENCIES_NUMBER_FORMAT[currency] ?? DEFAULT_NUMBER_FORMAT
  )
    .format(11111)
    .replace(/\p{Number}/gu, '')
  return parseFloat(
    stringNumber.replace(new RegExp('\\' + thousandSeparator, 'g'), '')
  )
}

const FormatHelper = {
  currency: (currency: string, amount: number) => {
    return (
      CURRENCIES_NUMBER_FORMAT_CURR[currency] ??
      Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code'
      })
    ).format(amount || 0)
  },

  amount: (amount: number) => {
    return DEFAULT_NUMBER_FORMAT.format(amount)
  },

  amountFormatWithOutCurrency: (amount: number, currency: string) => {
    return (CURRENCIES_NUMBER_FORMAT[currency] ?? DEFAULT_NUMBER_FORMAT).format(
      amount || 0
    )
  },

  amountInputFormatWithOutCurrency: (amount: string, currency: string) => {
    let getOriginAmount = parseLocaleNumber(amount, currency)
    return FormatHelper.amountFormatWithOutCurrency(getOriginAmount, currency)
  },

  getNumberFormSeparator: (amount: string, currency: string) => {
    if (amount === undefined || amount === null) {
      amount = '0'
    }
    return parseLocaleNumber(amount, currency)
  },

  humanDate: (date: Date) => {
    return dayjs(date).format('L LT')
  },
  localDate: (date: Date | string) => {
    return dayjs(date).format('D/M/YYYY HH:mm:ss')
  },
  trimSpace: (amount: string) => {
    return amount.trim()
  }
}

export default FormatHelper
