import React, { useContext } from 'react'
import {
  makeStyles,
  Box,
  Input,
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  colors,
  Divider,
  Chip
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Merchant from 'models/Merchant'
import Api from 'utils/Api'
import MultiSelect from '../../components/MultiSelect'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import MerchantSelect from 'components/MerchantSelect'
import { AppContext } from 'contexts/AppContext'
import Provider from 'models/Provider'
import { groupBy } from 'lodash'
import CurrencySelect from 'components/CurrencySelect'

export type Filter = {
  merchantId?: string
  currency?: string
  paymentSources?: string[]
  paymentMethod?: string[]
  period?: [Date | undefined, Date | undefined]
  //invoiceId: string

  // manual where
  where?: any
}

type Props = {
  onFilterChange: (filter: Filter) => void
  onSearchSubmit: () => void
}

const FilterForm: React.FC<Props> = ({ onFilterChange, onSearchSubmit }) => {
  const classes = useStyles()
  const { dispatch, state } = useContext(AppContext)
  const [merchantData, setMerchantData] = React.useState<null | Merchant>()
  const [filter, setFilter] = React.useState<Filter>({
    period: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
    currency: merchantData?.currency ? merchantData?.currency : 'IDR'
  })
  const [minFromDateSelect, setMinFromDateSelect] = React.useState<Date>()
  const [okDateRange, setOkDateRange] = React.useState(false)
  const [finalFilter, setFinalFilter] = React.useState<Filter>()
  const [providerGroup, setProviderGroup] = React.useState<string[]>(
    Object.keys(groupBy(state.resource.providers, 'category')).map((x) =>
      x == 'null' ? 'No Category' : x
    )
  )
  const [providers, setProviders] = React.useState<string[]>(
    state.resource.providers.map((x) => x.id)
  )

  const statusOptions = [
    'submitted',
    'failed',
    'declined',
    'pending',
    'authorized',
    'captured',
    'authenticate'
  ]

  const handleInputChange = (event: any) => {
    event.persist()
  }

  const handleInputKeyup = (event: any) => {
    event.persist()
    if (event.keyCode === 13) {
      emitChange()
    }
  }

  const emitChange = () => {
    onFilterChange(filter)
    if (filter.period && filter.period[1]) {
      var d = new Date(filter.period[1])
      d.setDate(filter.period[1].getDate() - 30)
      setMinFromDateSelect(d)
    }
  }

  const emitOnClick = () => {
    const merchant: any = state.session.user?.merchantId
    let changeFilter = 0
    let dataOk = true
    if (merchant != null) {
      if (changeFilter == 0) {
        filter.merchantId = merchant
      }
      changeFilter = 1
    }
    if (merchantData != null) {
      filter.currency = merchantData.currency
    }
    if (
      filter.period &&
      filter.period[1] &&
      filter.period[0] &&
      filter.period[1] < filter.period[0]
    ) {
      setOkDateRange(true)
      dataOk = false
    } else {
      setOkDateRange(false)
      dataOk = true
    }
    if (dataOk) {
      onSearchSubmit()
    }
  }
  React.useEffect(() => {
    const where: any = {}
    if (filter.paymentMethod && filter.paymentMethod.length > 0) {
      let category: string[] = []
      let isNullCategory: boolean = false
      filter?.paymentMethod.map((v) => {
        if (v == 'No Category') {
          isNullCategory = true
          where.or = [{ category: null }]
          return
        } else {
          category.push(v)
        }
      })

      if (isNullCategory) {
        where.or = [{ category: category }, { category: null }]
      } else {
        where.category = {
          inq: category
        }
      }
    }
    Api.request<Provider[]>({
      method: 'GET',
      url: '/providers',
      params: {
        filter: {
          where
        }
      }
    }).then(({ data }) => {
      dispatch({ type: 'SET_PROVIDERS', payload: data })
      setProviders(data.map((x) => x.id))
      if (providerGroup.length <= 0) {
        setProviderGroup(
          Object.keys(groupBy(data, 'category')).map((x) =>
            x == 'null' ? 'No Category' : x
          )
        )
      }
    })
  }, [filter])

  React.useEffect(() => {
    emitChange()
  }, [filter])

  return (
    <div>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <MerchantSelect
                  onChange={(merchant: null | Merchant) => {
                    setFilter({ ...filter, merchantId: merchant?.id })
                    setMerchantData(merchant)
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  backgroundColor: '#fff',
                  marginTop: '16px',
                  marginBottom: '16px',
                  width: '50%'
                }}
              >
                {merchantData ? (
                  <TextField
                    label="Currency"
                    variant="outlined"
                    fullWidth={true}
                    value={merchantData.currency}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                ) : (
                  <CurrencySelect
                    defaultCurrency={'IDR'}
                    onChange={(currency) => {
                      setFilter({
                        ...filter,
                        currency: currency?.id
                      })
                    }}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.fromDate}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="DD / MM / YYYY"
                  label="From date"
                  minDate={minFromDateSelect}
                  maxDate={
                    filter?.period
                      ? filter?.period[1]
                      : dayjs().subtract(1, 'day').toDate()
                  }
                  autoOk={true}
                  disableFuture={true}
                  value={filter.period ? filter.period[0] : undefined}
                  onChange={(date: dayjs.Dayjs | null) => {
                    setFilter({
                      ...filter,
                      period: [
                        date?.toDate(),
                        filter.period ? filter.period[1] : undefined
                      ]
                    })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  className={classes.white_bg}
                />
              </FormControl>
              <FormControl className={classes.toDate}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="DD / MM / YYYY"
                  label="To date"
                  autoOk={true}
                  maxDate={dayjs().toDate()}
                  maxDateMessage={"You cannot select today's date"}
                  disableFuture={true}
                  value={filter.period ? filter.period[1] : undefined}
                  onChange={(date: dayjs.Dayjs | null) => {
                    setFilter({
                      ...filter,
                      period: [
                        filter.period ? filter.period[0] : undefined,
                        date?.toDate()
                      ]
                    })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  className={classes.white_bg}
                />
              </FormControl>
              {okDateRange ? (
                <label
                  style={{
                    color: '#f74d4d',
                    fontSize: '12px',
                    marginTop: '4px',
                    paddingLeft: '16px'
                  }}
                >
                  Please select a date within 31 days.
                </label>
              ) : (
                ''
              )}
            </Grid>

            <Grid container spacing={2} className={classes.selectsContainer}>
              <label htmlFor="" style={{ color: '#939DA0' }}>
                Filters:{' '}
              </label>

              <Grid item>
                <MultiSelect
                  label="Payment Method"
                  options={providerGroup}
                  value={filter.paymentMethod || []}
                  onChange={(value) => {
                    setFilter({ ...filter, paymentMethod: value })
                  }}
                />
              </Grid>

              <Grid item>
                <MultiSelect
                  label="Payment Provider"
                  options={providers}
                  value={filter.paymentSources || []}
                  onChange={(value) => {
                    setFilter({ ...filter, paymentSources: value })
                  }}
                />
              </Grid>
              <Grid item>
                <div className={classes.chips}>
                  {filter.paymentSources?.map((chip) => (
                    <Chip className={classes.chip} key={chip} label={chip} />
                  ))}
                  {filter.paymentMethod?.map((chip) => (
                    <Chip className={classes.chip} key={chip} label={chip} />
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid>
              <Button
                style={{
                  width: 'auto',
                  fontSize: '14px',
                  lineHeight: '10px',
                  padding: '16px 22px 10px 22px',
                  backgroundColor: '#53B7D7',
                  color: 'white',
                  fontWeight: 'bold',
                  marginRight: '10px'
                }}
                fullWidth={true}
                variant="contained"
                size="large"
                onClick={emitOnClick}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '50%'
  },
  searchIcon: {
    color: theme.palette.text.hint
    // marginRight: theme.spacing(2)
  },
  selectsContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    //backgroundColor: colors.grey[50],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  chips: {
    // padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: '#CAE3F8'
  },
  chipFailed: {
    margin: theme.spacing(1),
    backgroundColor: '#D67976',
    color: 'white'
  },
  chipCaptured: {
    margin: theme.spacing(1),
    backgroundColor: '#92C48A',
    color: 'white'
  },
  fromDate: {
    padding: theme.spacing(2),
    width: '50%'
  },
  toDate: {
    padding: '16px 0px 0px 8px',
    width: '50%'
  },
  white_bg: {
    backgroundColor: 'white'
  }
}))

export default FilterForm