/* eslint-disable no-undef */
import React, { useEffect, ReactNode } from 'react'
// import { Helmet } from 'react-helmet'
import { useRouteMatch, Link } from 'react-router-dom'
import { Box, Breadcrumbs, makeStyles } from '@material-ui/core'
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import { RouteConfig } from 'react-router-config'
import Env from 'utils/Env'

const NODE_ENV = Env.nodeEnv
const GA_MEASUREMENT_ID = Env.gaMeasurementId

type PageProps = {
  children?: ReactNode
  className?: string
  title?: string
  breadcrumbs?: boolean
  routes?: BreadcrumbsRoute[]
}

const Page: React.FC<PageProps> = (props: PageProps) => {
  const { title, children, breadcrumbs, routes, ...rest } = props
  const cls = useStyles()

  const navbreadcrumbs = useBreadcrumbs(routes)
  const { path } = useRouteMatch()
  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return
    }

    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: path,
        page_name: title
      })
    }
  }, [title, path])
  return (
    <div {...rest}>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}
      <Box p={[1, 2, 4]} className={cls.pageHeaderSection}>
        {breadcrumbs ? (
          <Box marginBottom={[1, 2]}>
            <Breadcrumbs className={cls.bread}>
              {navbreadcrumbs.map(({ key, match, location, breadcrumb }) => (
                <Link key={key} to={match.url} className={cls.breadLink}>
                  {breadcrumb}
                </Link>
              ))}
            </Breadcrumbs>
          </Box>
        ) : null}

        {children}
      </Box>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  bread: {
    paddingBottom: 10,
    marginTop: '-10px',
    "& li":{
      color: 'white',
      opacity: '0.5',
      fontSize: '25px',
    },
    "& li:last-child":{
      opacity: '1 !important',
    },
    '@media(max-width: 1279px)': { 
      marginLeft: '45px',
    }
  },
  breadLink: {
    color: 'white',
    // fontSize: '1.4em',
    fontWeight: 'bold',
  },
  pageHeaderSection: {
    maxHeight: 192,
    background: 'linear-gradient(180deg, #4C9FD8 0%, #55BADB 81.77%)',
  },
}))

export { Page }
