/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import { matchPath, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { List, Typography } from '@material-ui/core'

import { NavigationListItem } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '10px'
  },
  sidebar_title_area: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 15px',
  },
  sidebar_title_text: {
    paddingLeft: '5px',
  },
  sidebar_title_arrow: {
    rotate: '270deg',
  }
}))

const NavigationList = (props) => {
  const { pages, ...rest } = props

  if (!(pages && pages.reduce)) return null //TODO: Fix this later

  return <List style={{paddingRight:'15px'}}>{pages.reduce((items, page) => reduceChildRoutes({ items, page, ...rest }), [])}</List>
}

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
}

const reduceChildRoutes = (props) => {
  const { router, items, page, depth } = props

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    })

    items.push(
      <NavigationListItem depth={depth} icon={page.icon} key={page.title} label={page.label} open={Boolean(open)} title={page.title}>
        <NavigationList depth={depth + 1} pages={page.children} router={router}/>
      </NavigationListItem>
    )
  } else {
    items.push(<NavigationListItem depth={depth} href={page.href} icon={page.icon} key={page.title} label={page.label} title={page.title} />)
  }

  return items
}

const Navigation = (props) => {
  const { title, pages, className, component: Component, ...rest } = props

  const classes = useStyles()
  const router = useRouteMatch()
  const [showDropdown, setShowDropdown] = useState(true)
  const toggleDropdown=()=>{
    if(showDropdown) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  }

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      {title && 
        <Typography variant="overline">
          <div className={clsx(classes.sidebar_title_area, className)} onClick={toggleDropdown}>
            <p className={clsx(classes.sidebar_title_text, className)}>{title}</p>
            <img className={showDropdown ? '' : clsx(classes.sidebar_title_arrow, className)} src="images/mwebapp/down.svg" alt=""/>
          </div>
        </Typography>
      }
      {
        showDropdown &&
        <NavigationList depth={0} pages={pages} router={router}/>
      }
    </Component>
  )
}

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  // pages: PropTypes.array.isRequired,
  pages: PropTypes.any, //TODO: for workaround, fix it later
  title: PropTypes.string
}

Navigation.defaultProps = {
  component: 'nav'
}

export default Navigation
