import React, { Suspense, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import { makeStyles } from '@material-ui/styles'
import MenuIcon from '@material-ui/icons/Menu'
import { LinearProgress, IconButton } from '@material-ui/core'
import { NavBar, TopBar } from './components'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: '#fff',
    position: 'relative',
  },
  // topBar: {
  //   //zIndex: 3,
  //   position: 'relative',
  //   color: '#000',
  //   backgroundColor: '#fff'
  // },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    //marginTop: 20
  },
  navBar: {
    zIndex: 2,
    // width: 256,
    minWidth: 230,
    flex: '0 0 auto'
  },
  content: {
    position: 'relative',
    flex: '1 1 auto',
    overflowY: 'auto'
  },
  menuIcon: {
    color: '#55BADB',
    position: 'absolute',
    top: '13px',
    //left: '-18px',
    left: '212px',
    zIndex: 9,
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    '&:hover':{
      backgroundColor: '#eeeeee !important'
    },
    '@media (max-width:1279px)': {
      left: '31px',
    }
  }
}))

type DashboardProps = {
  route?: { routes: any }
  onOpenNavBarMobile?: (...args: any[]) => any
}
const HomeLayout: React.SFC<DashboardProps> = (props) => {
  const { route, onOpenNavBarMobile } = props
  const classes = useStyles()
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true)
  }
  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false)
  }
  if (!(route && route.routes)) return null

  return (
    <div className={classes.root}>
      {/* <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
            showNavBar={true}
          /> */}
        <IconButton className={classes.menuIcon} onClick={handleNavBarMobileOpen}>
          <MenuIcon/>
        </IconButton>
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        {/* sidebar end */}

        {/* page content start by page route */}
        <main className={classes.content}>
          {/* <IconButton className={classes.menuIcon} onClick={handleNavBarMobileOpen}>
            <MenuIcon/>
          </IconButton> */}
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <ChatBar /> */}
    </div>
  )
}
export default HomeLayout
