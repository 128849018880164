import Currency from 'models/Currency'
import Provider from 'models/Provider'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : { type: Key; payload: M[Key] }
}

export type ResourceType = {
  counter: number
  currencies: Currency[]
  providers: Provider[]
}

export type ResourceActionPayload = {
  ADD_COUNTER: { count: number }
  SET_CURRENCIES: Currency[]
  SET_PROVIDERS: Provider[]
}

export type ResourceActionType =
  ActionMap<ResourceActionPayload>[keyof ActionMap<ResourceActionPayload>]
export const resourceReducer = (
  state: ResourceType,
  action: ResourceActionType
) => {
  switch (action.type) {
    case 'ADD_COUNTER':
      state.counter += action.payload.count
      return state

    case 'SET_CURRENCIES':
      state.currencies = action.payload
      return state
    case 'SET_PROVIDERS':
      state.providers = action.payload
    default:
      return state
  }
}
