import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  List,
  ListItem,
  Box,
  makeStyles,
  Card,
  ListItemText,
  ListSubheader,
  CardContent,
  Theme,
  Hidden
} from '@material-ui/core'
import { SyncAlt, Edit, Person, Settings, AccessTime } from '@material-ui/icons'
import { AppContext } from 'contexts/AppContext'
import { Link } from 'react-router-dom'
import AuthorizedComponent from 'modules/authentication/components/AuthorizedComponent'
import dayjs from 'dayjs'

const HomePage: React.SFC = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  return (
    <div>
      <div
        style={{
          background: 'linear-gradient(270deg, #4C9FD8 0%, #55BADB 81.77%)',
          position: 'relative',
          minHeight: '192px'
        }}
      >
        {/* <img className={classes.menu_icon} src="images/mwebapp/menu.svg" alt="" /> */}

        <p className={classes.mainTittel}>Quick Actions</p>
        <Card
          className={classes.content}
          style={{
            backgroundColor: '#EAF7FB',
            borderRadius: '12px 12px 0px 0px',
            margin: '20px 32px 0px 32px'
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                marginTop: '23px',
                color: '#2C5D70'
              }}
            >
              Welcome {state.session.user!.name}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ opacity: '0.5', marginBottom: '5px' }}
            >
              <AccessTime style={{ fontSize: 14 }} /> Last login:&nbsp;
              {dayjs(state.session.user!.lastLoggedTime).format('L LT')}
            </Typography>
          </CardContent>
        </Card>
      </div>

      <Card className={classes.content} style={{ borderBottom: 'unset' }}>
        <Grid container justify="flex-start">
          <AuthorizedComponent resource="transactions" scopes={['read']}>
            <Grid
              item
              className={classes.item}
              md={6}
              lg={3}
              style={{ borderRight: '1px solid #ebedef' }}
            >
              <Box m={2}>
                {/* <img src={`${process.env.PUBLIC_URL}/images/icon-transactions.png`} /> */}
                <SyncAlt color="primary" fontSize="large" />
              </Box>

              <List
                component="nav"
                subheader={
                  <ListSubheader>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="h3"
                      style={{ fontWeight: 'bold', color: '#13ABD9' }}
                    >
                      Transactions
                    </Typography>
                  </ListSubheader>
                }
              >
                <div
                  style={{
                    borderBottom: '2px solid #ebedef',
                    width: '34px',
                    marginLeft: '17px',
                    marginTop: '10px'
                  }}
                ></div>
                <AuthorizedComponent resource="transactions" scopes={['read']}>
                  <Link to="/transactions">
                    <ListItem button>
                      <ListItemText primary="Transactions" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
                <AuthorizedComponent resource="settlements" scopes={['read']}>
                  <Link to="/reconcile">
                    <ListItem button>
                      <ListItemText primary="Payment Report Upload" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
                <AuthorizedComponent resource="settlements" scopes={['read']}>
                  <Link to="/settlements">
                    <ListItem button>
                      <ListItemText primary="Settlements" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
                <AuthorizedComponent resource="msr" scopes={['read']}>
                  <Link to="/msr">
                    <ListItem button>
                      <ListItemText primary="MSR" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
                <AuthorizedComponent resource="settlements" scopes={['read']}>
                  <Link to="/disbursements">
                    <ListItem button>
                      <ListItemText primary="Disbursements" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>

                <AuthorizedComponent resource="void" scopes={['read']}>
                  <Link to="/void">
                    <ListItem button>
                      <ListItemText primary="Void" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>

                <AuthorizedComponent resource="refunds" scopes={['read']}>
                  <Link to="/refunds">
                    <ListItem button>
                      <ListItemText primary="Refunds" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
                <AuthorizedComponent resource="paymentlink" scopes={['read']}>
                  <Link to="/paymentlink">
                    <ListItem button>
                      <ListItemText primary="Payment Link" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
              </List>
            </Grid>
          </AuthorizedComponent>

          <Grid
            item
            className={classes.item}
            md={6}
            lg={3}
            style={{ borderRight: '1px solid #ebedef' }}
          >
            <Box m={2}>
              <Edit color="primary" fontSize="large" />
            </Box>
            <List
              component="nav"
              subheader={
                <ListSubheader>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="h3"
                    style={{ fontWeight: 'bold', color: '#13ABD9' }}
                  >
                    Manage
                  </Typography>
                </ListSubheader>
              }
            >
              <div
                style={{
                  borderBottom: '2px solid #ebedef',
                  width: '34px',
                  marginLeft: '17px',
                  marginTop: '10px'
                }}
              ></div>
              <AuthorizedComponent resource="partners" scopes={['read']}>
                <Link to="/partners">
                  <ListItem button>
                    <ListItemText primary="Partners list" />
                  </ListItem>
                </Link>
                <AuthorizedComponent resource="users" scopes={['read']}>
                  <Link to="/users/partner">
                    <ListItem button>
                      <ListItemText primary="Partners users" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
              </AuthorizedComponent>

              <AuthorizedComponent resource="merchants" scopes={['read']}>
                <Link to="/merchants">
                  <ListItem button>
                    <ListItemText primary="Merchant list" />
                  </ListItem>
                </Link>
              </AuthorizedComponent>
              <AuthorizedComponent resource="users" scopes={['read']}>
                <Link to="/users/merchant">
                  <ListItem button>
                    <ListItemText primary="Merchant users" />
                  </ListItem>
                </Link>
              </AuthorizedComponent>

              <AuthorizedComponent resource="acquirers" scopes={['read']}>
                <Link to="/acquirers">
                  <ListItem button>
                    <ListItemText primary="Acquirer list" />
                  </ListItem>
                </Link>
              </AuthorizedComponent>
              <AuthorizedComponent resource="users" scopes={['read']}>
                <Link to="/users/acquirer">
                  <ListItem button>
                    <ListItemText primary="Acquirer users" />
                  </ListItem>
                </Link>
              </AuthorizedComponent>
            </List>
          </Grid>

          <AuthorizedComponent resource="users" scope="create">
            <Grid
              item
              className={classes.item}
              md={6}
              lg={3}
              style={{ borderRight: '1px solid #ebedef' }}
            >
              <Box m={2}>
                <Person color="primary" fontSize="large" />
              </Box>
              <List
                component="nav"
                subheader={
                  <ListSubheader>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="h3"
                      style={{ fontWeight: 'bold', color: '#13ABD9' }}
                    >
                      Users
                    </Typography>
                  </ListSubheader>
                }
              >
                <div
                  style={{
                    borderBottom: '2px solid #ebedef',
                    width: '34px',
                    marginLeft: '17px',
                    marginTop: '10px'
                  }}
                ></div>
                <Link to="/users/admin">
                  <ListItem button>
                    <ListItemText primary="Bank admin users" />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          </AuthorizedComponent>

          <AuthorizedComponent resource="system" scope="create">
            <Grid item className={classes.item} md={6} lg={3}>
              <Box m={2}>
                <Settings color="primary" fontSize="large" />
              </Box>
              <List
                component="nav"
                subheader={
                  <ListSubheader>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="h3"
                      style={{ fontWeight: 'bold', color: '#13ABD9' }}
                    >
                      Settings
                    </Typography>
                  </ListSubheader>
                }
              >
                <div
                  style={{
                    borderBottom: '2px solid #ebedef',
                    width: '34px',
                    marginLeft: '17px',
                    marginTop: '10px'
                  }}
                ></div>
                <Link to="/settings">
                  <ListItem button>
                    <ListItemText primary="General settings" />
                  </ListItem>
                </Link>

                <Link to="/settings/velocity">
                  <ListItem button>
                    <ListItemText primary="Velocity settings" />
                  </ListItem>
                </Link>

                <Link to="/settings/binlist">
                  <ListItem button>
                    <ListItemText primary="BIN settings" />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          </AuthorizedComponent>
        </Grid>
      </Card>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: { margin: theme.spacing(4), padding: 0 },
  item: {
    padding: theme.spacing(3),
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        // borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  mainTittel: {
    padding: '17px 32px 0px 32px',
    fontSize: '25px',
    color: 'white',
    fontWeight: 'bold',
    '@media(max-width: 1279px)': {
      marginLeft: '45px'
    }
  }
  // menu_icon: {
  //   position: 'absolute',
  //   top: '15px',
  //   left: '-18px',
  //   cursor: 'pointer',
  //   zIndex: 10,
  //   '@media(max-width: 1280px)':{
  //     left: '5px',
  //   }
  // },
}))

export default HomePage
