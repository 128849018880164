import React, { useContext, useState } from 'react'
import { Grid, makeStyles, Card } from '@material-ui/core'
import { AppContext } from 'contexts/AppContext'
import { Page } from 'modules/layouts/Page'
import FilterForm, { Filter } from './FilterForm'
import LineChart from './LineChart'

const MonitoringPage: React.SFC = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)
  const [filter, setFilter] = useState<Filter>()
  const [finalFilter, setFinalFilter] = useState<Filter>()

  const onFilterChange = (filter: Filter) => {
    setFilter(filter)
  }
  const onSearchSubmit = () => {
    setFinalFilter(() => ({ ...filter }))
  }

  return (
    <div>
      <Page breadcrumbs={true} className={classes.search_page_top_section}>
        <Card className={classes.filter_section}>
          <FilterForm
            onFilterChange={onFilterChange}
            onSearchSubmit={onSearchSubmit}
          />
        </Card>
      </Page>
      {finalFilter && (
        <Card className={classes.chart_area}>
          <LineChart filter={finalFilter} />
        </Card>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: { margin: theme.spacing(4), padding: 0 },
  item: {
    padding: theme.spacing(3),
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        // borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  search_page_top_section: {
    height: '192px',
    background: 'linear-gradient(270deg, #4C9FD8 0%, #55BADB 81.77%)'
  },
  filter_section: {
    padding: '20px',
    backgroundColor: '#EDF6FB',
    borderRadius: '12px'
  },
  chart_area: {
    marginTop: '135px',
    padding: '30px',
    '@media(max-width: 960px)': {
      marginTop: '200px'
    }
  }
}))

export default MonitoringPage