import React, { Fragment, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles((theme) => ({
  root: {},
  menuItem: {
    padding: 0
  },
  formControlLabel: {
    // padding: theme.spacing(0.5, 2),
    width: '100%',
    margin: 0
  }
}))

type Props = {
  label: string
  onChange: (value: any) => void
  options: string[]
  value: string[]
  disabled?: boolean
}

const MultiSelect: React.FC<Props> = (props) => {
  const { label, options, value, onChange, disabled } = props

  const classes = useStyles()

  const anchorRef = useRef(null)

  const [openMenu, setOpenMenu] = useState(false)

  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }

  const handleOptionToggle = (event: any) => {
    let newValue = [...value]

    if (event.target.checked) {
      newValue.push(event.target.value)
    } else {
      newValue = newValue.filter((item) => item !== event.target.value)
    }

    onChange && onChange(newValue)
  }

  return (
    <Fragment>
      <Button
        onClick={handleMenuOpen}
        ref={anchorRef}
        disabled={disabled}
        style={{ backgroundColor: 'white', color: '#939DA0' }}
      >
        <span style={{ marginTop: '5px' }}>{label}</span>
        <ArrowDropDownIcon style={{ marginLeft: '60px' }} />
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        // className={classes.menu}
        onClose={handleMenuClose}
        open={openMenu}
        // eslint-disable-next-line react/jsx-sort-props
        PaperProps={{ style: { minWidth: 250 } }}
      >
        {options.map((option) => (
          <MenuItem className={classes.menuItem} key={option}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={value.indexOf(option) > -1}
                  color="primary"
                  onClick={handleOptionToggle}
                  value={option}
                />
              }
              label={option}
            />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default MultiSelect
