import React from 'react'
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { TransactionData } from './dashboard'

const DashboardBox: React.FC<{ data: TransactionData[] }> = ({ data }) => {
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table style={{ width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerStyle}>Today</TableCell>
            <TableCell className={classes.headerStyle} align="right">
              Monthly
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: TransactionData, key) => (
            <TableRow key={key}>
              <TableCell align="left">{row.today}</TableCell>
              <TableCell align="right">{row.monthly}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  headerStyle: {
    backgroundColor: '#F5F5F5',
    padding: '17px 32px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#283B42'
  },
  '@global tbody tr td': {
    padding: '15px 32px 12px !important'
  }
}))

export default DashboardBox