import React, {
  createContext,
  useReducer,
  Dispatch,
  useEffect,
  useState
} from 'react'
import {
  SessionType,
  sessionReducer,
  SessionActionType
} from 'reducers/SessionReducer'
import { StorageManager } from 'utils/StorageManager'
import { AxiosError } from 'axios'
import Api from 'utils/Api'
import LoadingScreen from 'components/LoadingScreen'
import FlashWrapper from 'components/flash/FlashWrapper'
import ApiErrorParser from 'utils/ApiErrorParser'
import SettlementApi from 'utils/SettlementApi'
import {
  ResourceActionType,
  resourceReducer,
  ResourceType
} from 'reducers/ResourceReducer'

const storageManager = new StorageManager()

type InitialStateType = {
  session: SessionType
  resource: ResourceType
}

const initialState = {
  session: {
    accessToken: storageManager.load('accessToken'),
    rememberOnDevice: false,
    // accessToken: null,
    newPassword: null,
    resetPassword: null,
    user: null,
    role: null,
    counter: 0
  },
  resource: {
    counter: 0,
    currencies: [],
    providers: []
  }
}

export type AuthorizedParams = {
  roles?: string[]
  resource?: string
  scope?: string
  scopes?: string[]
}

const AppContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<SessionActionType | ResourceActionType>
  flash: (m: string, success?: boolean) => void
  handleError: (e: string | AxiosError) => void
  isAuthorized: (params: AuthorizedParams) => boolean
}>({
  state: initialState,
  dispatch: () => null,
  flash: () => null,
  handleError: () => null,
  isAuthorized: () => false
})

if (initialState.session.accessToken) {
  Api.defaults.headers.common['Authorization'] =
    'Bearer ' + initialState.session.accessToken
  SettlementApi.defaults.headers.common['Authorization'] =
    'Bearer ' + initialState.session.accessToken
}

const mainReducer = (
  { session, resource }: InitialStateType,
  action: SessionActionType | ResourceActionType
) => ({
  session: sessionReducer(session, action as SessionActionType),
  resource: resourceReducer(resource, action as ResourceActionType)
})

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)
  const [flash, setFlash] = useState<{
    open: boolean
    variant: null | 'success' | 'warning' | 'error' | 'info'
    message: null | string
  }>({ open: false, variant: null, message: null })

  useEffect(() => {
    if (state.session && state.session.rememberOnDevice) {
      storageManager.save('accessToken', state.session.accessToken)
    }
    Api.request({
      method: 'GET',
      url: '/users/me'
    })
      .then(({ data }) => {
        Api.request({
          method: 'GET',
          url: '/users/me/role'
        }).then((roleRes) => {
          dispatch({ type: 'SET_ROLE', payload: roleRes.data })
          dispatch({ type: 'SET_USER', payload: data })
        })
      })
      .catch((err) => {
        dispatch({ type: 'SET_USER', payload: null })
        if (
          err &&
          err.response &&
          err.response.status === 401 &&
          window.location.pathname.indexOf('/auth') !== 0
        ) {
          console.error('You are unauthorized')
          window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
        }
      })
  }, [state.session.accessToken])

  // useEffect(() => {
  //   console.log('checking session', state.session.user)
  // }, [state.session.user])

  const isAuthorized: (params: AuthorizedParams) => boolean = ({
    roles,
    resource,
    scope,
    scopes
  }) => {
    let allow = false

    if (state.session.role && state.session.role.resources && resource) {
      const foundIndex = state.session.role.resources.findIndex((f) => {
        if (f.resource === resource) {
          if (scope && !scopes) {
            scopes = [scope]
          }

          if (scopes && scopes.length > 0) {
            // if(f.scopes.in)
            let fulfilled = true
            scopes.forEach((s) => {
              if (!f.scopes.includes(s)) {
                fulfilled = false
              }
            })
            return fulfilled
          }
        }
        return false
      })

      allow = foundIndex !== -1
    } else if (roles) {
      roles.forEach((role) => {
        if (state.session.user) {
          if (state.session.user?.roles) {
            if (state.session.user?.roles.indexOf(role) !== -1) {
              allow = true
            }
          }
        }
      })
    }
    return allow
  }

  const createFlash = (s: string, success?: boolean) => {
    setFlash({
      open: true,
      variant: success ? 'success' : 'info',
      message: s
    })
  }

  const handleError = (e: string | AxiosError) => {
    console.error(e)
    let res = (e as AxiosError)
    setFlash({
      open: true,
      variant: 'error',
      message: new ApiErrorParser(e).toString()
    })
    if (res && res.response?.status == 423){
      setTimeout(()=> window.location.replace('/signout'), 3000);
     
    }
  }

  let loading = state.session.user === null
  if (window.location.pathname.indexOf('/auth') === 0) {
    loading = false
  }

  return (
    <AppContext.Provider
      value={{ state, dispatch, flash: createFlash, handleError, isAuthorized }}
    >
      {loading ? <LoadingScreen /> : <>{children}</>}

      <FlashWrapper
        open={flash.open}
        flashVariant={flash.variant ? flash.variant : 'info'}
        flashMessage={flash.message || ''}
        onClose={() => {
          setFlash({ ...flash, open: false })
        }}
      />
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
