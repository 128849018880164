import React, { useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import LoadingComponent from './LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import Currency from 'models/Currency'
import Api from 'utils/Api'

const CurrencySelect: React.SFC<{
  defaultCurrency?: string | Currency | null
  disabled?: boolean
  onChange: (value: Currency | null) => void
}> = ({ defaultCurrency, onChange, ...props }) => {
  const { dispatch, state } = useContext(AppContext)
  const [currencies, setCurrencies] = React.useState<Currency[]>(
    state.resource.currencies
  )

  React.useEffect(() => {
    if (currencies && currencies.length > 0) return

    Api.get<Currency[]>('/currencies', {}).then(({ data }) => {
      dispatch({ type: 'SET_CURRENCIES', payload: data })
      setCurrencies(data)
    })

    // state.resource.
  }, [])
  if (!(currencies && currencies.length > 0)) return <LoadingComponent />

  let defaultValue: Currency | null = null
  if (defaultCurrency instanceof Currency) {
    defaultValue = defaultCurrency
  } else if (defaultCurrency) {
    defaultValue = currencies.find((f) => f.id === defaultCurrency) || null
  }

  return (
    <Autocomplete
      options={currencies}
      defaultValue={defaultValue}
      // getOptionLabel={(option) => option}
      fullWidth={true}
      getOptionLabel={(option) => `${option.id} - ${option.name}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label="-- Select Currency --"
          variant="outlined"
        />
      )}
      onChange={(ev: any, v: Currency | null) => {
        ev.persist()
        onChange(v)
      }}
      {...props}
    />
  )
}

export default CurrencySelect
