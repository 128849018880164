import React, { useState } from 'react'
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box
} from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { MerchantData } from './VelocityMonitoring'
import { Filter } from './FilterForm'
import Api from 'utils/Api'
import dayjs from 'dayjs'
import FormatHelper from 'utils/FormatHelper'
import LoadingComponent from 'components/LoadingComponent'
import Validator from 'models/Validator'

const VelocityTable: React.FC<{
  filter: Filter
}> = ({ filter }) => {
  const classes = useStyles()
  const [merchantData, setMerchantData] = useState<MerchantData[]>()
  const [valLabelText, setValLabelText] = React.useState<string>('')
  const [page, setPage] = React.useState(0)
  const [perPageRows, setPerPageRows] = React.useState(50)
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }
  React.useEffect(() => {
    let where: any = filter || {}
    where.limit = perPageRows
    where.offset = perPageRows * page

    if (filter.merchantId) {
      where.merchantId = filter.merchantId
    }
    where.velocityType = filter.velocityType
      ? filter.velocityType
      : 'maxtrxmerchant'
    where.status = 'captured'

    Api.request<MerchantData[]>({
      method: 'GET',
      url: '/reports/velocity-monitoring/summary',
      params: {
        filter: {
          where
        }
      }
    }).then(({ data }) => {
      setMerchantData(data)
    })

    Api.request<Validator[]>({
      method: 'GET',
      url: '/validators',
      params: {}
    }).then(({ data }) => {
      data.map((item: Validator) => {
        if (item.id == filter.velocityType) {
          let labelText: string = ''
          if ('apiburst' == item.id) {
            labelText = item.name + ' in last 1 minute'
          } else if (['panburst', 'trxburst'].includes(item.id)) {
            labelText = item.name + ' in last 1 hour'
          } else if (['maxtrxmerchant', 'maxtrxpan'].includes(item.id)) {
            labelText = item.name
          } else {
            labelText = item.name + ' in last 24 hours'
          }
          setValLabelText(labelText)
          return
        }
      })
    })

    if (filter.isFilterSubmit) {
      setPage(0)
      setPerPageRows(50)
    }

    filter.isFilterSubmit = false
  }, [filter, page, perPageRows])

  const dataFormat = (val: string, currency: string) => {
    let newData: string = ''
    let split = val.split('/')
    if (currency == '') {
      newData =
        Number(split[0]).toLocaleString() +
        ' / ' +
        Number(split[1]).toLocaleString()
    } else if (currency !== '') {
      newData =
        FormatHelper.currency(currency, Number(split[0])) +
        ' / ' +
        FormatHelper.amountFormatWithOutCurrency(Number(split[1]), currency)
    }
    return newData
  }

  const percentageFormat = (val: string) => {
    const colors = ['#6fbf73', '#ff9800', '#f44336']
    let score = 0
    if (Number(val) > 50 && Number(val) < 100) {
      score = 1
    } else if (Number(val) >= 100) {
      score = 2
    }
    return <span style={{ color: colors[score] }}>{val}%</span>
  }

  if (filter?.isFilterSubmit) {
    return <LoadingComponent />
  }
  const onChangeRowsPerPage = (event: any) => {
    setPerPageRows(Number(event.target.value))
    setPage(0)
  }
  return (
    <>
      <Box p={[1, 2]} display="flex" alignItems="center" width="full">
        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
          {valLabelText}
        </Typography>
        <Typography variant="h3" style={{ marginLeft: 'auto' }}>
          Report Time: {dayjs().format('DD/MM/YYYY HH.mm.ss').toString()}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table style={{ width: '100%' }} aria-label="simple table">
          {filter?.velocityType &&
          ['maxtrxmerchant', 'maxtrxpan'].includes(filter?.velocityType) ? (
            <>
              <TableHead>
                <TableRow>
                  {/* <TableCell className={classes.headerStyle} align="center">
                    Merchant ID
                  </TableCell> */}
                  <TableCell className={classes.headerStyle} align="left">
                    Merchant Name
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.headerStyle}
                    style={{
                      padding: '10px 0 0'
                    }}
                    align="center"
                  >
                    <span
                      style={{
                        paddingBottom: '10px',
                        display: 'block',
                        fontWeight: 'bold'
                      }}
                    >
                      Transaction
                    </span>

                    <Table>
                      <TableCell
                        className={classes.width2}
                        style={{
                          borderTop: '1px solid #ccc',
                          borderLeft: 'none',
                          padding: '10px 0 0'
                        }}
                        align="center"
                      >
                        <span
                          style={{
                            paddingBottom: '10px',
                            display: 'block',
                            fontWeight: 'bold'
                          }}
                        >
                          Daily
                        </span>
                        <Table>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderLeft: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Count
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderRight: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Treshold
                          </TableCell>
                        </Table>
                      </TableCell>
                      <TableCell
                        className={classes.width2}
                        style={{
                          borderTop: '1px solid #ccc',
                          borderLeft: 'none',
                          padding: '10px 0 0'
                        }}
                        align="center"
                      >
                        <span
                          style={{
                            paddingBottom: '10px',
                            display: 'block',
                            fontWeight: 'bold'
                          }}
                        >
                          Monthly
                        </span>
                        <Table>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderLeft: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Count
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderRight: 'none',
                              padding: '10px 0 0'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Treshold
                          </TableCell>
                        </Table>
                      </TableCell>
                      <TableCell
                        className={classes.width2}
                        style={{
                          borderTop: '1px solid #ccc',
                          borderRight: 'none',
                          borderLeft: 'none',
                          padding: '10px 0 0'
                        }}
                        align="center"
                      >
                        <span
                          style={{
                            paddingBottom: '10px',
                            display: 'block',
                            fontWeight: 'bold'
                          }}
                        >
                          Lifetime
                        </span>
                        <Table>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderLeft: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Count
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderRight: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Treshold
                          </TableCell>
                        </Table>
                      </TableCell>
                    </Table>
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.headerStyle}
                    style={{
                      padding: '10px 0 0'
                    }}
                    align="center"
                  >
                    <span
                      style={{
                        paddingBottom: '10px',
                        display: 'block',
                        fontWeight: 'bold'
                      }}
                    >
                      Volume
                    </span>
                    <Table>
                      <TableCell
                        className={classes.width2}
                        style={{
                          borderTop: '1px solid #ccc',
                          borderLeft: 'none',
                          padding: '10px 0 0'
                        }}
                        align="center"
                      >
                        <span
                          style={{
                            paddingBottom: '10px',
                            display: 'block',
                            fontWeight: 'bold'
                          }}
                        >
                          Daily
                        </span>
                        <Table>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderLeft: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Count
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderRight: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Treshold
                          </TableCell>
                        </Table>
                      </TableCell>
                      <TableCell
                        className={classes.width2}
                        style={{
                          borderTop: '1px solid #ccc',
                          borderLeft: 'none',
                          padding: '10px 0 0'
                        }}
                        align="center"
                      >
                        <span
                          style={{
                            paddingBottom: '10px',
                            display: 'block',
                            fontWeight: 'bold'
                          }}
                        >
                          Monthly
                        </span>
                        <Table>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderLeft: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Count
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderRight: 'none',
                              padding: '10px 0 0'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Treshold
                          </TableCell>
                        </Table>
                      </TableCell>
                      <TableCell
                        className={classes.width2}
                        style={{
                          borderTop: '1px solid #ccc',
                          borderRight: 'none',
                          borderLeft: 'none',
                          padding: '10px 0 0'
                        }}
                        align="center"
                      >
                        <span
                          style={{
                            paddingBottom: '10px',
                            display: 'block',
                            fontWeight: 'bold'
                          }}
                        >
                          Lifetime
                        </span>
                        <Table>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderLeft: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Count
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: '1px solid #ccc',
                              fontWeight: 'bold',
                              borderRight: 'none'
                            }}
                            className={classes.width1}
                            align="center"
                          >
                            Treshold
                          </TableCell>
                        </Table>
                      </TableCell>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  borderBottom: '1px solid #ccc'
                }}
              >
                {merchantData?.map((row: MerchantData, key) => (
                  <TableRow key={key}>
                    {/* <TableCell
                      align="center"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.mid}
                    </TableCell> */}
                    <TableCell
                      align="left"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.merchantName}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={{ border: '1px solid #ccc' }}
                    >
                      <Table style={{ width: '100%' }}>
                        <TableBody>
                          <TableCell
                            className={classes.width2}
                            align="center"
                            padding="none"
                          >
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <TableCell
                                  className={classes.width2}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {row.timeSpan['daily']
                                    ? dataFormat(
                                        row.timeSpan['daily'].transaction
                                          .totalTrx,
                                        ''
                                      )
                                    : 0}
                                </TableCell>
                                <TableCell
                                  className={classes.width2}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {percentageFormat(
                                    (row.timeSpan['daily']
                                      ? Number(
                                          row.timeSpan['daily'].transaction
                                            .percentage
                                        )
                                      : 0
                                    ).toFixed()
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell
                            className={classes.width2}
                            align="right"
                            padding="none"
                          >
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <TableCell
                                  className={classes.width2}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {row.timeSpan['monthly']
                                    ? dataFormat(
                                        row.timeSpan['monthly'].transaction
                                          .totalTrx,
                                        ''
                                      )
                                    : 0}
                                </TableCell>
                                <TableCell
                                  className={classes.width2}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {percentageFormat(
                                    (row.timeSpan['monthly']
                                      ? Number(
                                          row.timeSpan['monthly'].transaction
                                            .percentage
                                        )
                                      : 0
                                    ).toFixed()
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell
                            className={classes.width2}
                            align="right"
                            padding="none"
                          >
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <TableCell
                                  className={classes.width2}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {row.timeSpan['lifetime']
                                    ? dataFormat(
                                        row.timeSpan['lifetime'].transaction
                                          .totalTrx,
                                        ''
                                      )
                                    : 0}
                                </TableCell>
                                <TableCell
                                  className={classes.width2}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {percentageFormat(
                                    (row.timeSpan['lifetime']
                                      ? Number(
                                          row.timeSpan['lifetime'].transaction
                                            .percentage
                                        )
                                      : 0
                                    ).toFixed()
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={{ border: '1px solid #ccc' }}
                    >
                      <Table style={{ width: '100%' }}>
                        <TableBody>
                          <TableCell
                            className={classes.width2}
                            align="center"
                            padding="none"
                          >
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <TableCell
                                  className={classes.width1}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {row.timeSpan['daily']
                                    ? dataFormat(
                                        row.timeSpan['daily'].volume
                                          .totalTrxAmount,
                                        row.currency
                                      )
                                    : 0}
                                </TableCell>
                                <TableCell
                                  className={classes.width1}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {percentageFormat(
                                    (row.timeSpan['daily']
                                      ? Number(
                                          row.timeSpan['daily'].volume
                                            .percentage
                                        )
                                      : 0
                                    ).toFixed()
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell
                            className={classes.width2}
                            align="center"
                            padding="none"
                          >
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <TableCell
                                  className={classes.width1}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {row.timeSpan['monthly']
                                    ? dataFormat(
                                        row.timeSpan['monthly'].volume
                                          .totalTrxAmount,
                                        row.currency
                                      )
                                    : 0}
                                </TableCell>
                                <TableCell
                                  className={classes.width1}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {percentageFormat(
                                    (row.timeSpan['monthly']
                                      ? Number(
                                          row.timeSpan['monthly'].volume
                                            .percentage
                                        )
                                      : 0
                                    ).toFixed()
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell
                            className={classes.width2}
                            align="center"
                            padding="none"
                          >
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <TableCell
                                  className={classes.width1}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {row.timeSpan['lifetime']
                                    ? dataFormat(
                                        row.timeSpan['lifetime'].volume
                                          .totalTrxAmount,
                                        row.currency
                                      )
                                    : 0}
                                </TableCell>
                                <TableCell
                                  className={classes.width1}
                                  style={{ borderBottom: 'none' }}
                                  align="right"
                                >
                                  {percentageFormat(
                                    (row.timeSpan['lifetime']
                                      ? Number(
                                          row.timeSpan['lifetime'].volume
                                            .percentage
                                        )
                                      : 0
                                    ).toFixed()
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : null}
          {filter?.velocityType &&
          ['apiburst', 'panburst', 'trxburst'].includes(
            filter?.velocityType
          ) ? (
            <>
              <TableHead>
                <TableRow>
                  {/* <TableCell className={classes.headerStyle} align="center">
                    Merchant ID
                  </TableCell> */}
                  <TableCell className={classes.headerStyle} align="center">
                    Merchant Name
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.headerStyle}
                    style={{
                      padding: '10px 0 0'
                    }}
                    align="center"
                  >
                    <span
                      style={{
                        paddingBottom: '10px',
                        display: 'block',
                        fontWeight: 'bold'
                      }}
                    >
                      {'apiburst' == filter?.velocityType
                        ? 'Api Call/Minutes'
                        : 'Pan Burst'}
                    </span>
                    <Table>
                      <TableCell
                        style={{
                          borderTop: '1px solid #ccc',
                          fontWeight: 'bold',
                          borderLeft: 'none'
                        }}
                        className={classes.width1}
                        align="center"
                      >
                        Count
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: '1px solid #ccc',
                          fontWeight: 'bold',
                          borderRight: 'none'
                        }}
                        className={classes.width1}
                        align="center"
                      >
                        Treshold
                      </TableCell>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  borderBottom: '1px solid #ccc'
                }}
              >
                {merchantData?.map((row: MerchantData, key) => (
                  <TableRow key={key}>
                    {/* <TableCell
                      align="center"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.mid}
                    </TableCell> */}
                    <TableCell
                      align="left"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.merchantName}
                    </TableCell>
                    <TableCell
                      className={classes.width2}
                      align="center"
                      padding="none"
                      style={{ border: '1px solid #ccc' }}
                    >
                      <Table style={{ width: '100%' }}>
                        <TableBody>
                          <TableCell className={classes.width1} align="right">
                            {row.timeSpan['totalRows']
                              ? dataFormat(
                                  row.timeSpan['totalRows'].transaction
                                    .totalTrx,
                                  ''
                                )
                              : 0}
                          </TableCell>
                          <TableCell
                            className={classes.width1}
                            style={{ borderBottom: 'none' }}
                            align="right"
                          >
                            {percentageFormat(
                              (row.timeSpan['totalRows']
                                ? Number(
                                    row.timeSpan['totalRows'].transaction
                                      .percentage
                                  )
                                : 0
                              ).toFixed()
                            )}
                          </TableCell>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : null}
          {filter?.velocityType &&
          ![
            'maxtrxmerchant',
            'maxtrxpan',
            'apiburst',
            'panburst',
            'trxburst'
          ].includes(filter.velocityType) ? (
            <>
              <TableHead>
                <TableRow>
                  {/* <TableCell className={classes.headerStyle} align="center">
                    Merchant ID
                  </TableCell> */}
                  <TableCell className={classes.headerStyle} align="center">
                    Merchant Name
                  </TableCell>
                  <TableCell className={classes.headerStyle} align="center">
                    Transactions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  borderBottom: '1px solid #ccc'
                }}
              >
                {merchantData?.map((row: MerchantData, key) => (
                  <TableRow key={key}>
                    {/* <TableCell
                      align="center"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.mid}
                    </TableCell> */}
                    <TableCell
                      align="left"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.merchantName}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ border: '1px solid #ccc' }}
                    >
                      {row.timeSpan['totalRows']
                        ? Number(
                            row.timeSpan['totalRows'].transaction.totalTrx
                          ).toLocaleString()
                        : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : null}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 50, 100]}
        count={
          merchantData?.length && merchantData?.length >= perPageRows
            ? perPageRows * (page == 0 ? 1 : page + 1) + 1
            : perPageRows * page
        }
        page={page}
        rowsPerPage={perPageRows}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  headerStyle: {
    backgroundColor: '#F5F5F5',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#283B42'
  },
  width1: {
    width: '50%'
  },
  width2: {
    width: '33.33%'
  },
  '@global thead tr th': {
    border: '1px solid #ccc'
  },
  '@global tbody tr td': {
    borderBottom: 'none !important'
  },
  '@global ul.MuiPagination-ul': {
    justifyContent: 'end !important'
  },
  '@global .MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit':
    {
      display: 'none !important'
    },
  '@global .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot':
    {
      marginRight: '0 !important'
    }
}))

export default VelocityTable
