import axios from 'axios'
import Env from './Env'

const Api = axios.create({
  baseURL: Env.apiUrl,
  timeout: 60000
})

Api.defaults.baseURL = Env.apiUrl

Api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error && error.response && error.response.config) {
      if (
        error.response.status === 401 &&
        (!error.response.config.url.startsWith('/users') ||
          error.response.config.url == '/users')
      ) {
        window.localStorage.removeItem('accessToken')
        window.sessionStorage.clear()
        window.location.replace('/')
      }
    }

    return Promise.reject(error)
  }
)

export default Api
