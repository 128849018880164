import React, { useContext } from 'react'
import {
  makeStyles,
  Box,
  Input,
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  colors,
  Divider,
  Chip
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Merchant from 'models/Merchant'
import Api from 'utils/Api'
import MultiSelect from '../../components/MultiSelect'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import MerchantSelect from 'components/MerchantSelect'
import { AppContext } from 'contexts/AppContext'
import Provider from 'models/Provider'
import { groupBy } from 'lodash'
import Validator from 'models/Validator'
import VelocityTypeSelect from 'components/VelocityTypeSelect'
import { float } from 'html2canvas/dist/types/css/property-descriptors/float'

export type Filter = {
  merchantId?: string
  velocityType?: string
  isFilterSubmit?: boolean
  //invoiceId: string

  // manual where
  where?: any
}

type Props = {
  onFilterChange: (filter: Filter) => void
  onSearchSubmit: () => void
}

const FilterForm: React.FC<Props> = ({ onFilterChange, onSearchSubmit }) => {
  const classes = useStyles()
  const { dispatch, state } = useContext(AppContext)
  const [filter, setFilter] = React.useState<Filter>({})
  const [providerGroup, setProviderGroup] = React.useState<string[]>(
    Object.keys(groupBy(state.resource.providers, 'category')).map((x) =>
      x == 'null' ? 'No Category' : x
    )
  )
  const handleInputChange = (event: any) => {
    event.persist()
  }

  const handleInputKeyup = (event: any) => {
    event.persist()
    if (event.keyCode === 13) {
      emitChange()
    }
  }

  const emitChange = () => {
    onFilterChange(filter)
  }

  const emitOnClick = () => {
    const merchant: any = state.session.user?.merchantId
    let changeFilter = 0
    if (merchant != null) {
      if (changeFilter == 0) {
        filter.merchantId = merchant
      }
      changeFilter = 1
    }
    filter.isFilterSubmit = true
    onSearchSubmit()
  }
  React.useEffect(() => {
    Api.request<Provider[]>({
      method: 'GET',
      url: '/providers',
      params: {}
    }).then(({ data }) => {
      dispatch({ type: 'SET_PROVIDERS', payload: data })
      if (providerGroup.length <= 0) {
        setProviderGroup(
          Object.keys(groupBy(data, 'category')).map((x) =>
            x == 'null' ? 'No Category' : x
          )
        )
      }
    })
  }, [filter])

  React.useEffect(() => {
    emitChange()
  }, [filter])

  return (
    <div>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth={true}
                className={classes.formControl}
                style={{ marginLeft: 0 }}
              >
                <MerchantSelect
                  onChange={(merchant: null | Merchant) => {
                    setFilter({ ...filter, merchantId: merchant?.id })
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth={true}
                className={classes.formControl}
                style={{ marginLeft: 0, backgroundColor: '#fff' }}
              >
                <VelocityTypeSelect
                  defaultVelocityType={'maxtrxmerchant'}
                  removeType={['normalizer']}
                  onChange={(validator) => {
                    setFilter({
                      ...filter,
                      velocityType: validator?.id
                    })
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                style={{
                  width: 'auto',
                  fontSize: '14px',
                  lineHeight: '10px',
                  padding: '16px 22px 10px 22px',
                  backgroundColor: '#53B7D7',
                  color: 'white',
                  fontWeight: 'bold',
                  marginRight: '10px',
                  float: 'right'
                }}
                fullWidth={true}
                variant="contained"
                size="large"
                onClick={emitOnClick}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(2)
    // marginTop: theme.spacing(2)
  },
  searchIcon: {
    color: theme.palette.text.hint
    // marginRight: theme.spacing(2)
  },
  selectsContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    //backgroundColor: colors.grey[50],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  chips: {
    // padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: '#CAE3F8'
  },
  chipFailed: {
    margin: theme.spacing(1),
    backgroundColor: '#D67976',
    color: 'white'
  },
  chipCaptured: {
    margin: theme.spacing(1),
    backgroundColor: '#92C48A',
    color: 'white'
  },
  fromDate: {
    padding: theme.spacing(2),
    width: '50%'
  },
  toDate: {
    padding: '16px 0px 0px 8px',
    width: '50%'
  },
  white_bg: {
    backgroundColor: 'white'
  }
}))

export default FilterForm