const _env =
  window.process && window.process.env ? window.process.env : process.env
const Env = {
  nodeEnv: _env.NODE_ENV,
  apiUrl: _env.REACT_APP_API_URL,
  paymentUrl: _env.REACT_APP_PAYMENT_URL,
  settlementUrl: _env.REACT_APP_SETTLEMENT_URL,
  inquiryUrl: _env.REACT_APP_INQUIRY_URL,
  checkoutPageURL: _env.REACT_APP_CHECKOUT_PAGE_URL,
  visaMpiEnv: _env.REACT_APP_VISA_MPI_ENV,
  gaMeasurementId: _env.REACT_APP_GA_MEASUREMENT_ID
}

export default Env
