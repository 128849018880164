import React, { useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import LoadingComponent from './LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import Currency from 'models/Currency'
import Api from 'utils/Api'
import Validator from 'models/Validator'

const VelocityTypeSelect: React.SFC<{
  defaultVelocityType?: string | Validator | null
  removeType?: string[] | null
  disabled?: boolean
  onChange: (value: Validator | null) => void
}> = ({ defaultVelocityType, removeType, onChange, ...props }) => {
  const [validators, setValidators] = React.useState<Validator[]>([])

  React.useEffect(() => {
    Api.request<Validator[]>({
      method: 'GET',
      url: '/validators',
      params: {}
    }).then(({ data }) => {
      let newData: Validator[] = []
      data.map((item: Validator) => {
        if (!removeType?.includes(item.id)) {
          newData.push(item)
        }
      })
      setValidators(newData)
    })
  }, [])
  if (!(validators && validators.length > 0)) return <LoadingComponent />

  let defaultValue: Validator | null = null
  if (defaultVelocityType instanceof Validator) {
    defaultValue = defaultVelocityType
  } else if (defaultVelocityType) {
    defaultValue = validators.find((f) => f.id === defaultVelocityType) || null
  }

  return (
    <Autocomplete
      options={validators}
      defaultValue={defaultValue}
      fullWidth={true}
      getOptionLabel={(option) => `${option.id} - ${option.name}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label="-- Select Velocity Type --"
          variant="outlined"
        />
      )}
      onChange={(ev: any, v: Validator | null) => {
        ev.persist()
        onChange(v)
      }}
      {...props}
    />
  )
}

export default VelocityTypeSelect
