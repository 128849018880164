import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent
} from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import { AppContext } from 'contexts/AppContext'
import dayjs from 'dayjs'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import DashboardBox from './DashboardBox'

export type TransactionData = {
  today: string
  monthly: string
}
export type CurrencyData = {
  [currency: string]: TransactionData
}
export type CategoryData = {
  [category: string]: CurrencyData[]
}

const DashboardPage: React.SFC = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)
  const [transactionDashboard, setTransactionDashboard] = React.useState<
    CategoryData[]
  >([])
  React.useEffect(() => {
    let startMonth = dayjs().startOf('month').toISOString()
    let endDay = dayjs().endOf('day').toISOString()
    Api.get<CategoryData[]>('/reports/transaction-statistics', {
      params: {
        filter: {
          where: {
            status: 'captured',
            createdTime: {
              gt: startMonth,
              lt: endDay
            }
          }
        }
      }
    }).then(({ data }) => {
      setTransactionDashboard(data)
    })
  }, [])

  const newGenerateDataForTable = (data: any) => {
    let newData: TransactionData[] = []
    Object.keys(data).forEach((item) => {
      let currency = Object.keys(data[item])[0]
      let today = 0
      let monthly = 0
      if (currency) {
        today = data[item][currency]['today']
        monthly = data[item][currency]['monthly']
      }

      newData.push({
        today: FormatHelper.currency(currency, today),
        monthly: FormatHelper.currency(currency, monthly)
      })
    })
    return newData
  }
  return (
    <div>
      <div
        style={{
          background: 'linear-gradient(270deg, #4C9FD8 0%, #55BADB 81.77%)',
          position: 'relative',
          minHeight: '192px'
        }}
      >
        <p className={classes.mainTittel}>Transactions Dashboard</p>
        <Card
          className={classes.content}
          style={{
            backgroundColor: '#EAF7FB',
            borderRadius: '12px 12px 0px 0px',
            margin: '20px 32px 0px 32px'
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                marginTop: '23px',
                color: '#2C5D70'
              }}
            >
              Welcome {state.session.user!.name}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ opacity: '0.5', marginBottom: '5px' }}
            >
              <AccessTime style={{ fontSize: 14 }} /> Last login:&nbsp;
              {dayjs(state.session.user!.lastLoggedTime).format('L LT')}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <Grid container justify="center">
        <Grid item lg={12} xl={11}>
          <Grid container className={classes.panelContainer}>
            {transactionDashboard &&
              Object.entries(transactionDashboard).map((value, key) => {
                if (Number(value[1].length) <= 0) {
                  return null
                }
                return (
                  <Grid item xs={12} sm={6} lg={4}>
                    <Card
                      style={{
                        backgroundColor: '#EAF7FB',
                        borderRadius: '12px',
                        margin: '0 15px 25px',
                        border: 'none'
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="h4"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            marginBottom: '10px',
                            color: '#2C5D70'
                          }}
                        >
                          {value[0] == 'null' ? 'No Category' : value[0]}
                        </Typography>
                        <DashboardBox
                          data={newGenerateDataForTable(value[1])}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: { margin: theme.spacing(4), padding: 0 },
  item: {
    padding: theme.spacing(3),
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        // borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  panelContainer: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  mainTittel: {
    padding: '17px 32px 0px 32px',
    fontSize: '25px',
    color: 'white',
    fontWeight: 'bold',
    '@media(max-width: 1279px)': {
      marginLeft: '45px'
    }
  }
}))

export default DashboardPage