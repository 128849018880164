import React from 'react'
import Merchant from 'models/Merchant'
import Api from 'utils/Api'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Typography, Box } from '@material-ui/core'
import { AppContext } from 'contexts/AppContext'
import LabeledValue from './LabeledValue'
import lodash from 'lodash'

const allMerchant: any = {
  id: '__allMerchant',
  name: 'All Merchant'
}

const MerchantSelect: React.FC<{
  onChange: (value: Merchant | null) => void
  defaultMerchantId?: string
  partnerId?: string
  showAllMerchant?: boolean
}> = ({
  onChange,
  defaultMerchantId,
  partnerId,
  showAllMerchant = false,
  ...props
}) => {
  const { isAuthorized, state } = React.useContext(AppContext)

  const [merchants, setMerchants] = React.useState<Merchant[]>([])
  const [defaultMerchant, setDefaultMerchant] = React.useState<Merchant>()
  const [filteredMerchants, setFilteredMerchants] = React.useState<Merchant[]>(
    []
  )

  React.useEffect(() => {
    if (!isAuthorized({ resource: 'merchants', scope: 'read' })) {
      if (state.session.user && state.session.user.merchant)
        setMerchants([state.session.user?.merchant])
      return
    }
    Api.get<Merchant[]>('/merchants', {
      params: { filter: { where: { status: 'active' } } }
    }).then(({ data }) => {
      const defaultMerchant = data.find((d) => d.id === defaultMerchantId)
      setDefaultMerchant(defaultMerchant)
      setMerchants(data)
      if (showAllMerchant) {
        data.unshift(allMerchant)
      }
      setFilteredMerchants(data)
    })
  }, [])

  React.useEffect(() => {
    if (partnerId != '' && partnerId != undefined) {
      const test = lodash.filter(merchants, { partnerId: partnerId })
      setFilteredMerchants(test)
    }
  }, [partnerId])

  let placeHolderText = 'Select Merchant'
  if (showAllMerchant) placeHolderText = 'All Merchant'

  if (!merchants) return null

  return (
    <Autocomplete
      options={filteredMerchants}
      fullWidth={true}
      defaultValue={
        defaultMerchant
          ? defaultMerchant
          : state.session.user
          ? state.session.user.merchant
          : undefined
      }
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={defaultMerchant ? defaultMerchant.name : placeHolderText}
          variant="outlined"
          style={{ backgroundColor: 'white' }}
        />
      )}
      onChange={(e: any, v: Merchant | null) => {
        onChange(v)
      }}
      {...props}
    />
  )
}

export default MerchantSelect
